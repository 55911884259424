<template>
    <div>
      <el-row type="flex" align="middle">
        <el-col :span="12">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/income">我的收益</el-breadcrumb-item>
            <el-breadcrumb-item>确认收益</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-link type="primary" @click="$router.push('/feedback/'+incomeList[carouselIndex].id)">
            对账单有疑问？
          </el-link>
        </el-col>
      </el-row>
      <el-divider class="divDer"></el-divider>
      <div style="font-size: 24px;font-weight: bold;color: #333333;padding-top: 16px">
        您有<span style="color: #FF004D">{{ total }}</span>笔待确认账单
      </div>
      <div style="color: #333333;margin-top: 2px;margin-bottom: 24px;font-size: 14px;display: flex;justify-content: space-between">
        <div>为保证打款准确，请您按照账单顺序依次确认账单</div>
        <div v-if="incomeList.length>0">{{ carouselIndex + 1 }}/{{ total }}</div>
      </div>

      <div style="margin: 0 -16px" v-if="incomeList.length>0">
        <el-carousel height="194px" arrow="always" :loop="false" :autoplay="false" indicator-position="none" @change="handleCarouselChange">
          <el-carousel-item v-for="(item, index) in incomeList" :key="item.id">
            <div style="margin: 0 64px;background-color: rgba(255,0,77,0.05);height: 194px">
              <div style="padding: 30px" :class="{active:index !== 0}">
                <div style="font-size: 18px;color: #666666;font-weight: 500">{{ item.title }}</div>
                <div style="padding-top: 4px;padding-bottom: 24px">
                  <span style="font-size: 30px;font-weight: 800;color: #333333">{{ item.totalMoney }}</span>
                  <span style="color: #333333;font-size: 14px">元</span>&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style="color: #999999;font-size: 14px">(抵扣成本: {{ item.cost.toFixed(2) }} 抵扣预付: {{ item.deductAmount.toFixed(2) }})</span>
                </div>
                <div style="display: flex;justify-content: space-between">
                  <div style="font-size: 12px;color: #666666;line-height: 2">
                    <div style="display: flex">
                      <div style="width: 60px;color: #999999">账单分类</div>
                      <div style="width: 158px;">{{ item.type }}</div>
                      <div style="width: 60px;color: #999999">付款公司</div>
                      <div style="width: 158px;">{{ item.paymentCompany }}</div>
                    </div>
                    <div style="display: flex">
                      <div style="width: 60px;color: #999999">创建时间</div>
                      <div style="width: 158px;">{{ $timestampToString(item.createTime) }}</div>
                      <div style="width: 60px;color: #999999">账单编号</div>
                      <div style="width: 158px;">{{ item.id }}</div>
                    </div>
                  </div>
                  <el-button type="primary" :disabled="index !== 0" @click="dialogVisible = true"
                             style="width: 150px;height: 44px;border-radius: 3px"
                  >确认账单</el-button>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div >
      <income-detail-table  v-if="incomeList.length>0" :table-data="detailTable"></income-detail-table>
      <div class="NoBills" v-if="incomeList.length===0">
        <img src="../../assets/NoBills.png">
        <p class="title">暂无确认账单</p>
      </div>
      <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="548px"
          center
          :close-on-click-modal="false"
      >
        <span>账单一经确认不可撤回，请确认账单是否无误</span>
        <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
      </el-dialog>
    </div>
</template>

<script>
import {incomeDetailCheck, incomeList} from "@/api/income"
import IncomeDetailTable from "@/views/income/components/IncomeDetailTable"
/**
 * Confirm
 * 待确认列表
 */
export default {
  name: "Confirm",
  components: {
    IncomeDetailTable
  },
  data(){
    return {
      incomeList: [],
      detailTable: [],
      carouselIndex: 0,
      total: 0,
      dialogVisible: false,
    }
  },
  mounted() {
    this.getIncomeList()
  },
  methods: {
    getIncomeList() {
      incomeList({
        pageNum: 1,
        pageSize: 9999,
        status: 0,
      }).then(r => {
        this.incomeList = r.data.list.reverse()
        this.total = r.data.total
        this.detailTable = this.incomeList[0].incomeTwoLevelDetailsVoList
      })
    },
    handleCarouselChange(i) {
      this.carouselIndex = i
      this.detailTable = this.incomeList[i].incomeTwoLevelDetailsVoList
    },
    confirm(){
      incomeDetailCheck({} , {ids: this.incomeList[this.carouselIndex].id}).then(r => {
        if (r.code === 0) {
          this.getIncomeList()
          this.dialogVisible = false
          this.$popup({msg: '成功确认收益。', type:'success'})
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.divDer{
  margin-top: 18px;
  background-color: #f5f5f5;
}
.active{
  background: #f8f8f8 !important;
  .el-button{
    background: #dddddd !important;
    font-family: PingFang SC-Medium;
    font-weight: 500;
    color: #ffffff;
    border: none;
  }
}
::v-deep .el-dialog {
  .el-dialog__header {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    color: #222222;
    letter-spacing: 0px;
    padding-top: 40px;
    padding-bottom: 0px;
  }

  .el-dialog__body {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 48px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: CENTER;
    color: #222222;
    letter-spacing: 0px;
  }
  .el-dialog__footer {
    padding-bottom: 60px;
    padding-top: 0px;
    .el-button {
      width: 160px;
      height: 44px;
      margin: 0px 20px;
    }
  }
}
.NoBills{
  margin-top:130px;
  text-align: center;
  img{
    width: 80px;
    height: 80px;
  }
  .title{
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #999999;}
}
::v-deep .el-carousel__arrow{
  display: block!important;
}
</style>
